@import "~@fluentui/react/dist/sass/Fabric.scss";

.FolderWithFilesFoldable {
	.IconButton {
		margin-left: 5px;
		i {
			@include ms-fontSize-10;
		}
	}

	.Icon {
		padding-right: 5px;
	}

	.NestedFiles {
		padding-left: 21px;
	}
}
