@import "~@fluentui/react/dist/sass/Fabric.scss";

.Page-Wrapper {
	height: 100vh;
	background-color: #f3f2f1;
	.Page-Header {
		padding: 20px 20px 0px 20px;
		.LngDropdown {
			width: min-content;
			max-width: 100px;

			.ms-Dropdown-title {
				background-color: rgb(0, 120, 212);
				color: white;
			}

			.ms-Dropdown-caretDown {
				color: white;
			}
		}
	}

	.File-Download {
		display: flex;
		justify-content: center;

		.Page {
			align-self: center;
		}

		.Logo {
			/*animation: App-logo-spin infinite 20s linear;*/
			height: 30vmin;
			pointer-events: none;
		}

		.Header {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: calc(10px + 2vmin);
			color: white;
		}

		.Form {
			width: 50vw;
			min-width: 500px;
			max-width: 1500px;
			background-color: white;
			padding: 2em;
			align-self: center;
		}
	}

	.ExternalShareFileDetailListWrapper {
		width: 80vw;
		min-width: 600px;
		padding: 10px;
		background-color: white;
		-webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
		box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
	}
}
