@import '~@fluentui/react/dist/sass/Fabric.scss';

.ChecklistList {
	margin-block-start: 0em;
    margin-block-end: 0em;
    padding-inline-start: 1.3em;
}

.CheckListMemo {
	text-overflow: inherit;
	white-space: normal;
}