@import '~@fluentui/react/dist/sass/Fabric.scss';

.TaskStatus {
	padding: 2em;
}

.header-row {
	padding: 1em 0em;
}

.TaskNumber {
	@include ms-fontSize-24;
}

.TaskDescription {
	@include ms-fontSize-20;
}