.PTLimsStatus_Icon {
	vertical-align: -0.1rem;
	margin-right: 0.25rem;

	&_Ok {
		@extend .PTLimsStatus_Icon;
		color: #222A7B;
	}

	&_Working {
		@extend .PTLimsStatus_Icon;
		color: #6bb700;
	}

	&_Warning {
		@extend .PTLimsStatus_Icon;
		color: #ffaa44;
	}

	&_Error {
		@extend .PTLimsStatus_Icon;
		color: #D8252B;
	}
}

.PTLimsStatus_Reason {
	vertical-align: -0.1rem;
	margin-left: 0.25rem;
	color: #0078d4;
}