@import '~@fluentui/react/dist/sass/Fabric.scss';

.OrderStatus {
	padding: 2em;
}

.header-row {
	padding: 1em 0em;
}

.OrderNumber {
	@include ms-fontSize-24;
}

.OrderDescription {
	@include ms-fontSize-20;
}