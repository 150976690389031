.Loading {
	vertical-align: middle;
	text-align: center;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

.Loading-logo {
	height: 30vmin;
	pointer-events: none;
	margin-bottom: 2em;
	padding-right: 1.5em;
}